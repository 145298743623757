<template>
  <div class="card-document">
    <v-btn @click="removeFile" class="btn-top-right" icon color="primary">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <div class="anti-quebra">
      <a :href="path" target="_blank" v-if="path !== null && path !== undefined">
        <div class="box-icon">
          <v-icon>mdi-text-box</v-icon>
        </div>
        <h3>{{ title }}</h3>
      </a>
      <div v-else>
        <div class="box-icon">
          <v-icon>mdi-text-box</v-icon>
        </div>
        <h3>{{ title }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardImage",
  props: ["title", "path"],
  methods: {
    removeFile() {
      this.$emit("clickRemove", true);

    },
  },
};
</script>

<style scoped>
.card-document {
  margin: 10px 0;
  width: 100%;
  height: 150px;
  background: #ffffff;
  padding: 10px;
  border-radius: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14),
  0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2);
}

.box-icon {
  width: 70px;
  height: 70px;
  background: #edeafb;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  margin: auto;
}

.card-document .box-icon i {
  font-size: 50px;
  color: #3c2e88;
}

.card-document h3 {
  margin-top: 10px;
  color: #3c2e88;
  font-size: 20px;
}

.icon-button {
  font-size: 28px;
  color: #3c2e88;
}

.anti-quebra {
  max-width: 450px;
}

.anti-quebra h3 {
  font-size: 1em;
  word-break: break-all;
}

.btn-top-right {
  position: absolute;
  z-index: 2;
  margin-top: -100px;
  margin-right: -480px;
  /* transform: scale(2); */
}
</style>
